import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_sheet, {
                style: {"width":"500px"},
                class: "bg-grey-darken-4 pa-12",
                rounded: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, { class: "mx-auto px-6 py-8" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openGarage())),
                        "append-icon": "fas fa-warehouse",
                        color: "success",
                        size: "large",
                        variant: "elevated",
                        block: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Open Garage ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}